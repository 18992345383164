import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="">
      <blockquote class="quote">
        "There's a tremendous amount of craftsmanship 
        between having a great idea and having a great product.""
        <br></br>
        <br></br>
        - Steve Jobs
      </blockquote>
      <div class="flex-container-blog">
        {
          data.allMdx.nodes.map(node => (
            <article key={node.id}>
              <div class="flex-item-blog">
                <div class="blog-post-date">
                  Posted: {node.frontmatter.date}
                </div>

                <div class="blog-link">
                  <Link class="blog-link-no-uline " to={`/blog/${node.slug}`}>
                    <div class="flex-subject-container">  
                      <div class="flex-subject-item">
                          {node.frontmatter.title}
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="blog-tease">
                  {node.frontmatter.tease}
                </div>
              </div>
            </article>
          ))
        }
      </div>
        
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tease
          summary
        }
        id
        slug
      }
    }
  }
`

export default BlogPage